import React from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import customer1 from "../../Assets/img/customer-1.png";
import customer2 from "../../Assets/img/customer-2.png";
import customer3 from "../../Assets/img/customer-3.png";
import customer4 from "../../Assets/img/customer-4.jpeg";
import customer5 from "../../Assets/img/customer-5.jpeg";
import customer6 from "../../Assets/img/customer-6.jpeg";

const Land_Cust_FeedBack = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="container-fluid spacing_container">
          <div className="main_align">
            <div className="py-4 d-none d-lg-block">
              <div className="text-center">
                <div>
                  <p className="m-0 land_cust_feed_head_txt">
                    OUR CUSTOMERS
                    <FavoriteRoundedIcon className="cust_love_icon" />
                    US
                  </p>
                </div>
                <div className="land_cust_feed_sub_txt">
                  <span>And we’re sure you will too</span>
                </div>
              </div>

              <div
                id="carousel-feedback"
                className="carousel slide carousel-feedback"
                data-bs-ride="carousel"
                data-bs-interval="5000"
              >
                <div className="carousel-inner" style={{ overflow: "inherit" }}>
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer6}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>Anil Maddala</h4>
                              <h5>Bangalore</h5>
                            </div>
                            <p>
                              "Professionalism, Punctuality, Quality,
                              Responsiveness, Value Good quality and value for
                              money.. professional people to deal with."
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer2}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>Murali</h4>
                              <h5>New Zealand</h5>
                            </div>
                            <p>
                              “Right from helping us chose a site to delivering
                              top-notch construction, Cendrol has been a great
                              partner in the journey of building our home.”
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer3}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>Huzefa & family</h4>
                              <h5>USA</h5>
                            </div>
                            <p>
                              “Even being miles away in USA, Rahul kept us in
                              the loop about everything that involved building
                              our dream home. Cendrol did an amazing job at it.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer4}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>Nalin Diwan</h4>
                              <h5>Bangalore</h5>
                            </div>
                            <p>
                              "Rahul is a great person. He understands the need
                              of his clients very well. If you are looking to
                              build a dream home in reasonable budget go for
                              Cendrol. You will start appreciating your decision
                              right from the beginning."
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer5}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>NAGARJUN ARJUN</h4>
                              <h5>Bangalore</h5>
                            </div>
                            <p>
                              "My point of view is that Cendrol has 5 pillars:
                              Transparency in all details, Young Star Team,
                              Strong design, Systematic approach, Construction
                              guarantee Nothing more is required then this."
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card" style={{ border: "none" }}>
                          <div className="mainHappyCustomerBox">
                            <div className="customerImage">
                              <img
                                className="lazy"
                                src={customer1}
                                alt=""
                                title=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text-center">
                              <h4>Rohan</h4>
                              <h5>Bangalore</h5>
                            </div>
                            <p>
                              "Site Engineer is always present on site, working
                              hard to get tasks done. I am grateful to Cendrol
                              for building within the promised time-frame and
                              budget, without any issues or complaints on the
                              way."
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className="land_feedback_arrow_prev"
                    type="button"
                    data-bs-target="#carousel-feedback"
                    data-bs-slide="prev"
                  >
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 0L5.715 0.6965L1.925 4.5H12V5.5H1.925L5.715 9.2865L5 10L0 5L5 0Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </button>
                  <button
                    className="land_feedback_arrow_next"
                    type="button"
                    data-bs-target="#carousel-feedback"
                    data-bs-slide="next"
                  >
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0L6.285 0.6965L10.075 4.5H0V5.5H10.075L6.285 9.2865L7 10L12 5L7 0Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="pt-4 d-lg-none d-sm-block">
              <div className="text-center">
                <div>
                  <p className="m-0 land_cust_feed_head_txt">
                    OUR CUSTOMERS
                    <FavoriteRoundedIcon className="cust_love_icon" />
                    US
                  </p>
                </div>
                <div className="land_cust_feed_sub_txt">
                  <span>And we’re sure you will too</span>
                </div>
              </div>

              <div
                id="carousel-feedback1"
                className="carousel slide carousel-feedback"
                data-bs-ride="carousel"
                data-bs-interval="5000"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer1}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>Rohan</h4>
                          <h5>Bangalore</h5>
                        </div>
                        <p>
                          "Site Engineer is always present on site, working hard
                          to get tasks done. I am grateful to Cendrol for
                          building within the promised time-frame and budget,
                          without any issues or complaints on the way."
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer2}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>Murali</h4>
                          <h5>New Zealand</h5>
                        </div>
                        <p>
                          “Right from helping us chose a site to delivering
                          top-notch construction, Cendrol has been a great
                          partner in the journey of building our home.”
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer3}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>Huzefa & family</h4>
                          <h5>USA</h5>
                        </div>
                        <p>
                          “Even being miles away in USA, Rahul kept us in the
                          loop about everything that involved building our dream
                          home. Cendrol did an amazing job at it.”
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer4}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>Nalin Diwan</h4>
                          <h5>Bangalore</h5>
                        </div>
                        <p>
                          "Rahul is a great person. He understands the need of
                          his clients very well. If you are looking to build a
                          dream home in reasonable budget go for Cendrol. You
                          will start appreciating your decision right from the
                          beginning."
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer5}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>NAGARJUN ARJUN</h4>
                          <h5>Bangalore</h5>
                        </div>
                        <p>
                          "My point of view is that Cendrol has 5 pillars:
                          Transparency in all details, Young Star Team, Strong
                          design, Systematic approach, Construction guarantee
                          Nothing more is required then this."
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div
                      className="card"
                      style={{ padding: "10px", border: "none" }}
                    >
                      <div className="mainHappyCustomerBox">
                        <div className="customerImage">
                          <img
                            className="lazy"
                            src={customer6}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                        <div className="text-center">
                          <h4>Anil Maddala</h4>
                          <h5>Bangalore</h5>
                        </div>
                        <p>
                          "Professionalism, Punctuality, Quality,
                          Responsiveness, Value Good quality and value for
                          money.. professional people to deal with."
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-center pt-4"
                style={{ gap: "15px" }}
              >
                <button
                  className="land_present_arrow_btn"
                  type="button"
                  data-bs-target="#carousel-feedback1"
                  data-bs-slide="prev"
                >
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.64984 0.823242L7.53307 1.68362L2.85131 6.38205H15.2969V7.61734H2.85131L7.53307 12.2948L6.64984 13.1762L0.473379 6.9997L6.64984 0.823242Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <button
                  className="land_present_arrow_btn"
                  type="button"
                  data-bs-target="#carousel-feedback1"
                  data-bs-slide="next"
                >
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.35016 0.823242L8.46693 1.68362L13.1487 6.38205H0.703125V7.61734H13.1487L8.46693 12.2948L9.35016 13.1762L15.5266 6.9997L9.35016 0.823242Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Cust_FeedBack;
