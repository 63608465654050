import React from "react";

export default function Faqs() {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className="container-fluid spacing_container">
            <div className="main_align">
              <div className="faq_txt">
                <span className="land_faq_head_txt">
                  Frequently Asked Questions
                </span>
                <br />
                <span className="land_faq_sub_txt">
                  Over 65+ residential homes built and delivered successfully
                </span>
              </div>
              <div className="accordion p-0" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div>
                        What activities does Cendrol Construction cover?
                      </div>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We take up Design-and-Build type contracts, which means
                        all we need is your requirements of your dream home. We
                        design from scratch (in-house or architect) and take
                        care of site construction, as well liasoning and
                        material procurement.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div>Why should I choose Cendrol?</div>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We take care of end-to-end project activities, right
                        from conceptualisation, to approvals, to site
                        construction. Our contract covers all activities under
                        one roof, which saves you time, money and effort!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How do you assure and control the quality of construction?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        All our brand partners for materials and fixtures are
                        verified and well-known. There is a site engineer on
                        site to overlook every activity and make sure that
                        quality is not compromised. Moreover, regular updates
                        (once a day) of the progress are given directly to the
                        client via WhatsApp.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFour">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How do you commit on-time completion of project?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We have pre-planned schedule and timeline for every
                        project, which our engineers strictly follow. We work on
                        milestone-basis, and raise invoices only at the end of
                        every milestone. Moreover, our ready-to-build designs
                        service ensures fast tracking of the initial stages of
                        the project
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFive">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      How do you ensure transparency of payments?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        Our payments are milestone-basis. We raise timely
                        detailed invoices at the completion of each milestone.
                        We also provide cash-flow analysis if asked for it.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingSix">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      What drawings do you provide?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        For Design Visualisation- floorplans, elevations and
                        realistic 3D views For Site Construction- Structural
                        drawings, basic MEP drawings, and working drawings as
                        required by site engineers and procurement manager .
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingSeven">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      How do choose your sub-contractors/labour teams?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We have trusted sub-contractor teams who have been
                        working with us for a long time. Also, we conduct
                        regular evaluation of these teams and take the necessary
                        steps to maintain efficiency of work. For specialised
                        works, we release request for tenders from pre-qualified
                        sub-contractors.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingEight">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I track the progress of my site?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseEight"
                    className="collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We provide detailed updates at the end of every day via
                        WhatsApp. We also provide 24-hour assistance via our
                        Website. Also, our exclusive tracking and scheduling App
                        is updated with the progress at all times. You can
                        access it from anywhere!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingNine">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      Whom do I contact before and during my project?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseNine"
                    className="collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We have a single point of contact, i.e. a Relationship
                        Manager from the Business Development team, who is
                        updated about the project progress at all times, so that
                        your experience of construction with us is hassle-free!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTen">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How do I know about the materials used in constructing my
                      Home?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseTen"
                    className="collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        There are different packages with per.sq.ft. rates which
                        we offer you at the beginning of the project. You choose
                        the package that suits you the best. Every package
                        consists of the details about all materials and fixtures
                        with their brand names and model numbers (if
                        applicable). The per.sq.ft. rates are decided on the
                        basis of these materials and fixtures.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingEleven">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      Do you have a warranty on your construction?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseEleven"
                    className="collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        We provide 4 free inspections plus upto 1 year free
                        maintenance post-construction. Apart from this, if there
                        are damages from the construction team’s side, we cover
                        them up under the defects liability clause in our
                        contract.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
