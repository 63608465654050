import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import Cendrol from "../../Assets/img/logo.svg";
import {
  validateName,
  validateEmail,
  validateMobileNumber,
  validtaeOTP,
} from "./Validation";
import gtag, { install } from "ga-gtag";

const CostCalculatorNav = () => {
  const removePopup1 = () => {
    window.$("#thankyounew").modal("hide");
  };

  const [otp, setotp] = useState("");
  const [number, setnumber] = useState("");

  // Handle Change for Number & To send Otp
  const handleChangeNumber1 = (e) => {
    setnumber(e.target.value);
  };

  // Handle Change for OTP
  const handleChangeOTP1 = (e) => {
    setotp(e.target.value);
    let otpverify = e.target.value;
    if (otpverify.length === 4) {
      verifyOtp(otpverify);
    }
  };

  const verify_btn = () => {
    var regex = /^[0-9]{10}$/;
    if (number.length === 10) {
      if (number.match(regex)) {
        axios
          .post(`${process.env.REACT_APP_BASE_UR}/sendotp`, { mobile: number })
          .then((res) => {
            let type = res.data.msg.type;
            if (type === "success") {
              document.getElementById("verify_btn").style.display = "none";
              document.getElementById("otp_sent1").style.display = "block";
              document.getElementById("user_otp").style.display = "block";
              document.getElementById("resendlink").style.pointerEvents =
                "auto";
            } else {
              alert("internal error");
            }
          });
      }
    }
  };

  // Reset otp
  const resetOTP = () => {
    setotp("");
  };

  // Verifyotp
  const verifyOtp = (otpverify) => {
    axios
      .post(`${process.env.REACT_APP_BASE_UR}/verifyOtp`, {
        mobile: number,
        otp: otpverify,
      })
      .then((res) => {
        let type = res.data.msg.type;
        if (type === "success") {
          document.getElementById("otp_verified1").style.display = "block";
          document.getElementById("otp_mismatch1").style.display = "none";
          // document.getElementById("signup").style.pointerEvents = "auto";
          document.getElementById("signup").style.display = "block";
        } else if (type === "error") {
          resetOTP();
          document.getElementById("otp_mismatch1").style.display = "block";
          document.getElementById("otp_verified1").style.display = "none";
        }
      });
  };

  // TO Resend OTP
  const resendOTP1 = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_UR}/resendOtp`, {
        mobile: number,
      })
      .then((res) => {
        if (res.data.msg.type === "success") {
          document.getElementById("otp_sent1").style.display = "none";
          document.getElementById("otp_re_sent").style.display = "block";
        } else if (res.data.msg.type === "error") {
          alert("OTP limit reached please try again");
        } else {
          alert("Mainteance Error!!!");
        }
      });
  };

  // DM scripts
  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-10857610158/hXzmCNK9xLEDEK73p7ko",
      value: 1.0,
      currency: "INR",
      event_callback: callback,
    });
    return false;
  }

  const SaveUserData1 = (data, resetForm) => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_UR}/homeleads`,
        {
          name: data.fullname,
          email: data.email,
          mobile: number,
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          gtag_report_conversion();
          window.$("#modalForm").modal("hide");
          window.$("#thankyounew").modal("show");
          resetForm({ data: "" });
          localStorage.setItem("Verify", "True");
          localStorage.setItem("User", number);
          localStorage.setItem("Email", data.email);
          localStorage.setItem("Name", data.fullname);

          setnumber("");
          setotp("");
        } else if (res.data.status === "failed") {
          alert("Details Already Exists");
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };

  return (
    <div style={{ position: "sticky", zIndex: "9999" }}>
      <div>
        <div
          className="row justify-content-center"
          style={{ display: "block" }}
        >
          <div className="col-11 col-md-11">
            <div>
              <nav className="navbar navbar-expand-lg fixed-top navbar-light build_nav">
                <div className="container-fluid navbar_container">
                  <a href="/">
                    <img src={Cendrol} className="cendrol_log" alt="" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse land_nav_toggle"
                    id="navbarTogglerDemo02"
                  >
                    <div className="navbar-nav ms-auto">
                      <div className="navbar_li_div align-self-center">
                        <ul className="navbar-nav land_nav_ul">
                          <li
                            className="nav-item align-self-center px-3"
                            style={{ listStyleImage: "unset" }}
                          >
                            <a
                              className="align-items-center"
                              href="mailto:sales@cendrol.com"
                              title="sales@cendrol.com"
                            >
                              {/* <DraftsIcon className="nav_mail_icons" /> */}
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.6897 11.53C18.1197 11.38 17.4497 11.3 16.6497 11.3C15.5397 11.3 15.1297 11.57 14.5597 12C14.5297 12.02 14.4997 12.05 14.4697 12.08L13.5197 13.09C12.7297 13.94 11.2797 13.94 10.4797 13.09L9.52969 12.09C9.49969 12.05 9.46969 12.02 9.43969 12C8.85969 11.57 8.44969 11.3 7.34969 11.3C6.54969 11.3 5.87969 11.37 5.30969 11.53C2.92969 12.17 2.92969 14.06 2.92969 15.72V16.65C2.92969 19.16 2.92969 22 8.27969 22H15.7197C19.2697 22 21.0697 20.2 21.0697 16.65V15.72C21.0697 14.06 21.0697 12.17 18.6897 11.53Z"
                                  fill="#FDC315"
                                />
                                <path
                                  d="M14.7891 2H9.20906C4.78906 2 4.78906 4.35 4.78906 6.42V12.21C4.78906 12.43 4.88906 12.63 5.05906 12.76C5.22906 12.89 5.45906 12.94 5.66906 12.88C6.11906 12.76 6.67906 12.7 7.34906 12.7C8.01906 12.7 8.15906 12.78 8.55906 13.08L9.46906 14.04C10.1191 14.74 11.0491 15.14 12.0091 15.14C12.9691 15.14 13.8891 14.74 14.5491 14.04L15.4591 13.08C15.8591 12.78 15.9991 12.7 16.6691 12.7C17.3391 12.7 17.8991 12.76 18.3491 12.88C18.5591 12.94 18.7791 12.89 18.9591 12.76C19.1291 12.63 19.2291 12.42 19.2291 12.21V6.42C19.2091 4.35 19.2091 2 14.7891 2Z"
                                  fill="#0A0A0A"
                                />
                                <path
                                  d="M13.55 9.91H10.45C10.06 9.91 9.75 9.6 9.75 9.21C9.75 8.82 10.06 8.51 10.45 8.51H13.55C13.94 8.51 14.25 8.82 14.25 9.21C14.25 9.59 13.93 9.91 13.55 9.91Z"
                                  fill="white"
                                />
                                <path
                                  d="M14.3287 7.12H9.66875C9.27875 7.12 8.96875 6.81 8.96875 6.42C8.96875 6.03 9.27875 5.72 9.66875 5.72H14.3187C14.7087 5.72 15.0188 6.03 15.0188 6.42C15.0188 6.8 14.7087 7.12 14.3287 7.12Z"
                                  fill="white"
                                />
                              </svg>
                              sales@cendrol.com
                            </a>
                          </li>
                          <li
                            className="nav-item align-self-center px-3"
                            style={{ listStyleImage: "unset" }}
                          >
                            <a
                              className="align-items-center"
                              href="tel:9900717477"
                              title="990-071-7477"
                            >
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.615 4.80378C12.1985 4.72684 11.8159 4.99265 11.7366 5.39925C11.6573 5.80585 11.924 6.20282 12.3292 6.28239C13.5492 6.52023 14.4912 7.46458 14.7299 8.68874V8.68961C14.7979 9.042 15.1081 9.2982 15.4654 9.2982C15.5133 9.2982 15.5613 9.29383 15.6101 9.28508C16.0153 9.20376 16.2819 8.80766 16.2026 8.40019C15.8462 6.57182 14.4389 5.15966 12.615 4.80378"
                                  fill="#FDC315"
                                />
                                <path
                                  d="M12.5609 1.75694C12.3657 1.72896 12.1696 1.78667 12.0136 1.91083C11.8533 2.03675 11.7531 2.21862 11.7313 2.42236C11.6851 2.8342 11.9823 3.20669 12.3936 3.25304C15.23 3.56957 17.4347 5.77918 17.7536 8.62447C17.7963 9.00571 18.1161 9.29339 18.4978 9.29339C18.5266 9.29339 18.5545 9.29164 18.5832 9.28814C18.7828 9.26628 18.9605 9.16748 19.086 9.01008C19.2106 8.85269 19.2673 8.65683 19.2446 8.45659C18.8473 4.90653 16.0997 2.15129 12.5609 1.75694"
                                  fill="#FDC315"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.65277 11.3508C13.1432 14.8403 13.9351 10.8034 16.1574 13.0242C18.3 15.1662 19.5314 15.5953 16.8168 18.3091C16.4768 18.5824 14.3164 21.87 6.72403 14.2797C-0.869294 6.6885 2.41639 4.52589 2.68972 4.18596C5.41088 1.46462 5.83263 2.70321 7.97518 4.84516C10.1976 7.06693 6.16233 7.86136 9.65277 11.3508Z"
                                  fill="#0A0A0A"
                                />
                              </svg>
                              +91 9900717477
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalForm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal_content_whole">
              <div className="modal-body">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="modal_head_txt">
                        Planning To Build Your
                        <br /> Dream Home In Chennai?
                      </div>
                      <div className="modal_sub_txt pt-2">
                        Get complete package details directly to your inbox.
                      </div>
                    </div>
                    <div
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <CloseIcon style={{ fontSize: "29px" }} />
                    </div>
                  </div>

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      fullname: "",
                      email: "",
                      number: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      SaveUserData1(values, resetForm);
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="pt-4">
                            <div className="pb-3">
                              <label
                                htmlFor="fullName"
                                className="user_form_label"
                              >
                                Enter Your Name
                              </label>

                              <Field
                                type="text"
                                name="fullname"
                                className="form-control user_form_fileds"
                                id="fullName"
                                placeholder="eg. Sample Name"
                                autoComplete="off"
                                validate={validateName}
                              />
                              {errors.fullname && touched.fullname && (
                                <span className="errors">
                                  {errors.fullname}
                                </span>
                              )}
                            </div>

                            <div className="pb-3">
                              <label
                                htmlFor="email"
                                className="user_form_label"
                              >
                                Enter Your Email
                              </label>
                              <Field
                                type="email"
                                name="email"
                                className="form-control user_form_fileds"
                                id="email"
                                placeholder="eg. sample@gmail.com"
                                autoComplete="off"
                                validate={validateEmail}
                              />
                              {errors.email && touched.email && (
                                <span className="errors">{errors.email}</span>
                              )}
                            </div>

                            <div className="pb-3">
                              <label
                                htmlFor="number"
                                className="user_form_label"
                              >
                                Phone Number
                              </label>

                              <div className="d-flex">
                                <span className="number_edit_91">+91</span>
                                <div className="d-flex w-100 p-0 align-items-center user_form_fileds">
                                  <Field
                                    type="tel"
                                    className="form-control user_form_fileds"
                                    id="number"
                                    name="number"
                                    placeholder="eg. 70900XXXXX"
                                    autoComplete="off"
                                    validate={validateMobileNumber}
                                    onKeyUp={(e) => handleChangeNumber1(e)}
                                    onWheel={(e) => e.target.blur()}
                                    maxLength={10}
                                    onKeyDown={(event) => {
                                      if (!/[0-9Backspace]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                      ["e", "a", "p", "s", "c", "k"].includes(
                                        event.key
                                      ) && event.preventDefault();
                                    }}
                                  />
                                  <div style={{ paddingRight: "5px" }}>
                                    <button
                                      id="verify_btn"
                                      className="btn_verify"
                                      onClick={verify_btn}
                                      type="submit"
                                    >
                                      Verify
                                    </button>

                                    <span
                                      id="otp_sent1"
                                      className="otp_sent"
                                      style={{
                                        display: "none",
                                        width: "max-content",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      OTP Sent
                                    </span>
                                    <span
                                      id="otp_re_sent"
                                      className="otp_sent"
                                      style={{
                                        display: "none",
                                        width: "max-content",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      OTP Resend Successfully
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {errors.number && touched.number && (
                                <span className="errors">{errors.number}</span>
                              )}
                            </div>

                            <div id="user_otp" style={{ display: "none" }}>
                              <div className="pb-3">
                                <label
                                  htmlFor="otp"
                                  className="user_form_label"
                                >
                                  Enter Your OTP
                                </label>

                                <Field
                                  type="tel"
                                  name="otp"
                                  className="form-control user_form_fileds"
                                  id="otp"
                                  placeholder="Enter OTP sent to your mobile number"
                                  autoComplete="off"
                                  onKeyUp={(e) => handleChangeOTP1(e)}
                                  onWheel={(e) => e.target.blur()}
                                  maxLength={4}
                                  onKeyDown={(event) => {
                                    if (!/[0-9Backspace]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                    ["e", "a", "p", "s", "c", "k"].includes(
                                      event.key
                                    ) && event.preventDefault();
                                  }}
                                  validate={validtaeOTP}
                                />
                                {errors.otp && touched.otp && (
                                  <span className="errors">{errors.otp}</span>
                                )}

                                <span
                                  id="otp_verified1"
                                  className="otp_sent"
                                  style={{ display: "none", textAlign: "left" }}
                                >
                                  OTP verified
                                </span>
                                <span
                                  id="otp_mismatch1"
                                  className="otp_worong"
                                  style={{ display: "none", textAlign: "left" }}
                                >
                                  Wrong OTP...ReEnter
                                </span>
                              </div>

                              <div className="pb-3">
                                <span className="form_OTP_not">
                                  Don’t Received OTP?{" "}
                                </span>
                                <span
                                  id="resendlink"
                                  className="resend_otp"
                                  onClick={() => resendOTP1(number)}
                                >
                                  Resend OTP
                                </span>
                              </div>
                            </div>

                            <div id="btn_signup">
                              <button
                                id="signup"
                                type="submit"
                                className="popup_form_btn"
                              >
                                Get Your Free Estimate
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Thank you */}
      <div
        className="modal fade"
        id="thankyounew"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "9999" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center thankyou_heading">
                Thank you for
                <br /> your interest in Cendrol.
              </div>
              <div className="d-flex justify-content-center">
                <div className="thank_line"></div>
              </div>
              <p className="text-center thank_subheading">
                One of our Sales Representatives will be in <br />
                contact with you shortly.
              </p>
              <div>
                <button className="w-100 keep_browsing" onClick={removePopup1}>
                  Keep Browsing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculatorNav;
