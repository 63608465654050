import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";

export default function PackageSection() {
  return (
    <div>
      <div className="heading-center">
        <h2>OUR EXCLUSIVE PACKAGES</h2>
        <p className="sub-heading1">
          Explore Our Exclusive Home Construction Bangalore Package That Are
          Suited To Your Custom Requirements.
        </p>
      </div>
      <div className="pt-4">
        <div className="accordion accordion-flush" id="accordionExample1">
          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 p-2">
                  <div className="package_inner_divs">
                    <div
                      className="d-flex pack_first_card mb-2"
                      style={{ gap: "15px" }}
                    >
                      <div>
                        <div className="package_svg">
                          <svg
                            width="63"
                            height="63"
                            viewBox="0 0 63 63"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="63"
                              height="63"
                              rx="3"
                              fill="url(#paint0_linear_1625_9111)"
                            />
                            <path
                              d="M19.314 21.499H22.8138L29.3759 17.9687C29.9505 18.5458 30.7314 18.8701 31.5458 18.8701C32.36 18.8701 33.1409 18.5458 33.7154 17.9687L40.3126 21.499H43.8124C43.8923 21.4947 43.9729 21.4947 44.0529 21.499L34.5645 16.4289C34.605 16.2257 34.6255 16.0191 34.6258 15.8119C34.6258 14.7179 34.0423 13.7069 33.0948 13.1601C32.1473 12.613 30.9798 12.613 30.0323 13.1601C29.0848 13.7069 28.5013 14.7179 28.5013 15.8119C28.5016 16.0191 28.522 16.2257 28.5626 16.4289L19.0742 21.5121C19.1542 21.5033 19.2344 21.499 19.3147 21.499L19.314 21.499ZM31.5632 14.4993V14.4997C31.9113 14.4997 32.2452 14.6379 32.4911 14.8838C32.7374 15.1301 32.8756 15.4639 32.8756 15.8118C32.8756 16.16 32.7374 16.4939 32.4911 16.7401C32.2452 16.9861 31.9114 17.1243 31.5632 17.1243C31.215 17.1243 30.8811 16.9861 30.6352 16.7401C30.3889 16.4939 30.2507 16.16 30.2507 15.8118C30.2507 15.464 30.3889 15.1301 30.6352 14.8838C30.8811 14.6379 31.215 14.4997 31.5632 14.4997V14.4993Z"
                              fill="#0A0A0A"
                            />
                            <path
                              d="M43.8107 23.249H19.3124C18.8437 23.249 18.4104 23.4989 18.176 23.905C17.9413 24.3112 17.9413 24.8114 18.176 25.2175C18.4104 25.6237 18.8437 25.8736 19.3124 25.8736H43.8107C44.2794 25.8736 44.7128 25.6237 44.9471 25.2175C45.1818 24.8114 45.1818 24.3112 44.9471 23.905C44.7128 23.4989 44.2794 23.249 43.8107 23.249Z"
                              fill="#0A0A0A"
                            />
                            <path
                              d="M20.1876 41.2032C20.186 41.9725 20.3874 42.7287 20.7716 43.3954C21.1555 44.0619 21.7088 44.6157 22.3749 45.0002L31.5617 50.2498L40.7486 45.0002C41.4148 44.6157 41.968 44.0619 42.3519 43.3954C42.736 42.7286 42.9375 41.9725 42.9359 41.2032V27.6417H20.1875L20.1876 41.2032ZM24.8775 35.3324C24.98 35.019 25.1692 34.741 25.4231 34.5304C25.677 34.3198 25.9852 34.1853 26.3123 34.1422L28.8717 33.7703L30.0136 31.4517L30.0132 31.452C30.2064 31.0586 30.5403 30.752 30.9489 30.593C31.3572 30.434 31.8103 30.434 32.219 30.593C32.6273 30.752 32.9611 31.0586 33.1543 31.452L34.2962 33.7706L36.8555 34.1425V34.1422C37.2882 34.2051 37.6819 34.4279 37.9586 34.7666C38.2354 35.105 38.3755 35.5353 38.3511 35.972C38.3267 36.4086 38.1396 36.8203 37.8265 37.1258L35.9367 38.9326L36.3743 41.4831C36.4396 41.9097 36.345 42.3451 36.1088 42.7061C35.8726 43.0671 35.5113 43.3283 35.0945 43.4391C34.6777 43.5502 34.2343 43.5032 33.8501 43.3073L31.562 42.1175L29.274 43.3073C29.0228 43.4434 28.7415 43.5139 28.4558 43.5129C27.9456 43.5068 27.4635 43.2786 27.1358 42.8877C26.8077 42.4968 26.6671 41.9823 26.7498 41.4788L27.1874 38.9326L25.3369 37.1304C25.0994 36.9039 24.9304 36.6152 24.8492 36.2973C24.768 35.9793 24.7778 35.6449 24.8776 35.3324L24.8775 35.3324Z"
                              fill="#0A0A0A"
                            />
                            <path
                              d="M28.8963 39.225L28.4587 41.7712L30.7468 40.5683L30.7471 40.568C31.2564 40.3004 31.8649 40.3004 32.3742 40.568L34.6622 41.7709L34.2246 39.2247L34.2249 39.225C34.1276 38.6574 34.3155 38.0782 34.7278 37.6764L36.5783 35.8695L34.0236 35.4975C33.7422 35.4551 33.4752 35.3456 33.2448 35.1784C33.0187 35.0169 32.8344 34.8039 32.7068 34.5571L32.1862 33.5028L31.5606 32.2211L31.3025 32.746L30.4276 34.544C30.174 35.0594 29.6834 35.4177 29.1151 35.5022L26.543 35.8696L28.3935 37.6718C28.807 38.075 28.9952 38.656 28.8964 39.2251L28.8963 39.225Z"
                              fill="#0A0A0A"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1625_9111"
                                x1="31.5"
                                y1="-15.75"
                                x2="57.2524"
                                y2="64.9022"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#FFE185" />
                                <stop offset="1" stopColor="#F7BA00" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span className="pack_headings">Standard</span>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div className="first_comp_heading">
                          Design & Drawing
                        </div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <div
                              className="d-flex"
                              style={{ width: "100%", gap: "20%" }}
                            >
                              <ul>
                                <li>2D Floor Plan</li>
                                <li>3D Elevation</li>
                                <li>Structural Design</li>
                              </ul>
                              <ul>
                                <li>MEP</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <div className="first_comp_heading">Structure</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>Floor to Ceiling Height - 10ft.</li>
                              <li>Steel (FE 500/550 TMT Bars) - Prime Gold</li>
                              <li>
                                Solid Concrete Blocks - Sobha blocks (6 inch & 4
                                inch)
                              </li>
                              <li>Cement (grade 53&43) - ACC/Birla</li>
                              <li>
                                Concrete - M20 & M25 grade RMC as per Structural
                                Designer recommendation
                              </li>
                              <li>Water Proofing - Dr. Fixit</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        <div className="first_comp_heading">
                          Flooring & Wall Tiles
                        </div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                Living ,Kitchen,Dining & Bed room:
                                Granite/vitrified tiles -
                                <span className="rs_symbol">₹</span>
                                120/Sq.Ft.
                              </li>
                              <li>
                                Stair Case & Entrance : Granite -{" "}
                                <span className="rs_symbol"> ₹</span>
                                100/Sq.Ft
                              </li>
                              <li>
                                Kitchen wall - 2 feet,{" "}
                                <span className="rs_symbol"> ₹</span>
                                60/Sq.Ft
                              </li>
                              <li>
                                Bathroom floor & Wall tiles{" "}
                                <span className="rs_symbol"> ₹</span>
                                60/Sq.Ft
                              </li>
                              <li>
                                Utility Floor & wall tiles – 4 feet,{" "}
                                <span className="rs_symbol"> ₹</span>
                                50/Sq.Ft
                              </li>
                              <li>
                                Balcony tiles -
                                <span className="rs_symbol"> ₹</span>
                                50/Sq.Ft
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        <div className="first_comp_heading">Kitchen</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                {" "}
                                Kitchen counter top : Granite -
                                <span className="rs_symbol"> ₹</span>
                                150/Sq.Ft
                              </li>
                              <li>
                                Kitchen wall - 2 feet above Granite top -
                                <span className="rs_symbol"> ₹</span>
                                60/Sq.Ft
                              </li>
                              <li>
                                Stainless steel - Sink & tap up to -
                                <span className="rs_symbol"> ₹</span>
                                7000/-
                              </li>
                              <li>
                                1 RO - Fixture Provision - up to -
                                <span className="rs_symbol"> ₹</span>
                                2000/-
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                      >
                        <div className="first_comp_heading">Dining fixture</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                {" "}
                                Hand wash basin and tap upto{" "}
                                <span className="rs_symbol">₹</span>
                                3000/-
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                      >
                        <div className="first_comp_heading">Bathroom</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                Bathroom floor & Wall tiles 7 feet height -{" "}
                                <span className="rs_symbol">₹</span>
                                60/Sq.Ft
                              </li>
                              <li>
                                Sanitary ware & CP Fittings - Upto -{" "}
                                <span className="rs_symbol">₹</span>
                                40000/1000 Sq.Ft.SBUA
                              </li>
                              <li>CPVC Pipe - Ashirvad/ Supreme</li>
                              <li>
                                Bathroom Accessories - EWC, Health Faucet, Wash
                                Basin with accessories, 2 in 1 wall mixer,
                                Overhead Shower.{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        <div className="first_comp_heading">
                          Doors & Windows
                        </div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                Main Door - Teak door 3'6" x 7' -{" "}
                                <span className="rs_symbol">₹</span>
                                25000 And Teak frame of 5" x 3", worth -{" "}
                                <span className="rs_symbol">₹</span>
                                2700/CFT
                              </li>
                              <li>
                                Pooja door - Teak door –{" "}
                                <span className="rs_symbol">₹</span>
                                15000 Teak Frame -{" "}
                                <span className="rs_symbol">₹</span>
                                1800/CFT
                              </li>
                              <li>
                                Bedrooms Standard flush door -{" "}
                                <span className="rs_symbol">₹</span>
                                150/Sq.ft with Red Sal frame – 4” x 2.5”
                                thicknes -<span className="rs_symbol"> ₹</span>
                                1450/CFT
                              </li>
                              <li>
                                Bathroom - WPC -
                                <span className="rs_symbol"> ₹</span>
                                250/Sq.ft
                              </li>
                              <li>UPVC windows with Ms grill</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="true"
                        aria-controls="collapseEight"
                      >
                        <div className="first_comp_heading">
                          Doors & Windows fixtures
                        </div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                Main door - Europa Lock & Door Accessories -{" "}
                                <span className="rs_symbol">₹</span>
                                3500/- for 1 qty
                              </li>
                              <li>
                                Pooja room - Aldrop - 8"- 2qty, 2 front Handle
                                6", Hinges - 4" - 6 qty, tower bolts 8" & 4"{" "}
                                <span className="rs_symbol">₹</span>
                                1000/- for 1 qty
                              </li>
                              <li>
                                bed room & Bathroom - Standard lock door handle,
                                hinges 5" - 3qty, 8" & 4" tower bolts, front and
                                back door handle, door magnet{" "}
                                <span className="rs_symbol">₹</span>
                                650/- for 1 qty
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="true"
                        aria-controls="collapseNine"
                      >
                        <div className="first_comp_heading">Painting</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                Interior Painting - JK wall Putty + Asian primer
                                + Asian Standard Emulsion
                              </li>
                              <li>
                                Exterior Painting - Asian Primer + Apex Exterior
                                Emulsion Paint
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="true"
                        aria-controls="collapseTen"
                      >
                        <div className="first_comp_heading">Electrical</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>Wires - Finolex or Havells</li>
                              <li>Switches & Socket - GM/Havells</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse11"
                        aria-expanded="true"
                        aria-controls="collapse11"
                      >
                        <div className="first_comp_heading">Elevation</div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapse11"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>
                                {" "}
                                <span className="rs_symbol">₹</span>
                                25000/- Front Side Only
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item collapse_strips">
                      <div
                        className="d-flex justify-content-between accordion-header"
                        id="headingOne"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse12"
                        aria-expanded="true"
                        aria-controls="collapse12"
                      >
                        <div className="first_comp_heading">
                          Construction Warranty
                        </div>
                        <AddIcon className="package_collapse_icon" />
                      </div>
                      <div
                        id="collapse12"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body p-0">
                          <div className="design_points">
                            <ul>
                              <li>10 year Warranty Civil Structure</li>
                              <li>
                                1 year Warranty For Works Other Than The Civil
                                Structure
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
