import React from "react";

const CostCalculatorFaqs = () => {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className="container-fluid spacing_container">
            <div className="main_align">
              <div className="faq_txt">
                <span className="land_faq_head_txt">
                  Frequently Asked Questions
                </span>
                <br />
                <span className="land_faq_sub_txt">
                  Over 65+ residential homes built and delivered successfully
                </span>
              </div>
              <div className="accordion p-0" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div>
                        What is the standard cost of construction per sq ft?
                      </div>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        The average construction cost of a 1,000 sq ft home may
                        vary between Rs 1,300 per sq ft and Rs 5,000 per sq ft.
                        Home construction cost depends on multiple factors,
                        including civil and finishing costs; hence, it varies
                        considerably across locations.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div>How is the total construction area calculated?</div>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        If the size of the plot or land being used for a project
                        is 500 sq ft and the FAR determined for that particular
                        city/locality is 1.5, then, the total floor area that
                        can be constructed will be 750 sq ft (500×1.5) according
                        to far calculation.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingThree">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How do you estimate costs for a project?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        1. List all the steps involved in bringing your project
                        to fruition. <br />
                        2. Compute your internal labour costs.
                        <br /> 3. Figure your external labour costs. <br />
                        4. Research the materials you’ll need to complete the
                        project. <br />
                        5. Tally the cost for all these materials. <br />
                        6. Add an additional percentage to allow for cost
                        overruns, overtime pay and cost increases in supplies
                        during the duration of your project. <br />
                        7. Establish a procedure to track project costs
                        throughout the duration of the project.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFour">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How do you calculate the construction cost per sqft?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        The formula to calculate price per square foot is price
                        divided by size (in square feet). So for example, if you
                        have a 2,000-square-foot house selling for $300,000 you
                        take the total price, then divide it by the square
                        footage, which would give you $150 per square foot.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingFive">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      What are the different methods to estimate construction
                      project cost?
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <p className="m-0">
                        Methods of Cost Estimation in Projects <br />
                        1. Expert Judgement <br />
                        2. Analogous Estimating <br />
                        3. Parametric Estimating <br />
                        4. Bottom-up Estimating <br />
                        5. Three-point Estimating <br />
                        6. Data Analysis (Alternative analysis/Reserve analysis)
                        <br />
                        7. Project Management Information system <br />
                        8. Decision making (voting)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculatorFaqs;
