import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import CostCalculator from "./Components/Landing/CostCalculator";
import Thankyou from "./Components/Landing/Thankyou";
import { useEffect } from "react";

function App() {
  return (
    <Router basename={"/"}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route
            path="/house-construction-cost-calculator"
            element={<CostCalculator />}
          />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
