import React from "react";
import Footer from "./Footer";
import NavBar from "./Navbar";
import { Link } from "react-router-dom";
export default function Thankyou() {
  return (
    <div>
      <section>
        <NavBar />
      </section>
      <div className="thankyou_main">
        <div>
          <div className="p-3">
            <div className="text-center thankyou_heading_page">
              Thank you for
              <br /> your interest in Cendrol.
            </div>
            <div className="d-flex justify-content-center">
              <div className="thanku_line"></div>
            </div>
            <p className="text-center thank_subheading_page">
              One of our Sales Representatives will be in contact with you
              shortly.
            </p>
            <div className="d-flex justify-content-center mb-3 mt-4">
              <Link to="/" className="side_menu_dashboardbtn_unactive">
                <button className="keep_browsing">Keep Browsing</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section className="footer">
        <Footer />
        <div className="boxCopyright">
          <div className="container">
            <p className="mb-0">
              © {new Date().getFullYear()} www.cendrol.com All rights reserved
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
