import React from "react";
import Present1 from "../../Assets/img/present-img1.png";
import Present2 from "../../Assets/img/present-img2.png";
import Present3 from "../../Assets/img/present-img3.png";
import Present4 from "../../Assets/img/present-img4.png";
import Present5 from "../../Assets/img/present-img5.png";
import Present6 from "../../Assets/img/present-img6.png";
import Present7 from "../../Assets/img/present-img7.png";
import Present8 from "../../Assets/img/present-img8.png";
import Present9 from "../../Assets/img/present-img9.png";
import Present10 from "../../Assets/img/present-img10.png";
import Present11 from "../../Assets/img/present-img11.png";
import Present12 from "../../Assets/img/present-img12.png";
import Present13 from "../../Assets/img/present-img13.png";
import Present14 from "../../Assets/img/present-img14.png";
import Present15 from "../../Assets/img/present-img15.png";
import Present16 from "../../Assets/img/present-img16.png";
import Present17 from "../../Assets/img/present-img17.png";
import Present18 from "../../Assets/img/present-img18.png";
import Present19 from "../../Assets/img/present-img19.png";
import Present20 from "../../Assets/img/present-img20.png";
import Present21 from "../../Assets/img/present-img21.png";
import Present22 from "../../Assets/img/present-img22.png";
import Present23 from "../../Assets/img/present-img23.png";
import Present24 from "../../Assets/img/present-img24.png";
import Present25 from "../../Assets/img/present-img25.jpg";
import Present26 from "../../Assets/img/present-img26.jpg";
import Present27 from "../../Assets/img/present-img27.jpg";

export default function Presents() {
  return (
    <div className="land_present">
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className="container-fluid spacing_container">
            <div className="main_align">
              <div className="py-4 d-none d-lg-block">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="land_present_in_txt">Present in -</span>
                  </div>

                  <div>
                    <div
                      className="d-flex carousel-button-group"
                      style={{ gap: "15px" }}
                    >
                      <button
                        className="land_present_arrow_btn"
                        type="button"
                        data-bs-target="#multi-item-example"
                        data-bs-slide="prev"
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 0L5.715 0.6965L1.925 4.5H12V5.5H1.925L5.715 9.2865L5 10L0 5L5 0Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                      <button
                        className="land_present_arrow_btn"
                        type="button"
                        data-bs-target="#multi-item-example"
                        data-bs-slide="next"
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 0L6.285 0.6965L10.075 4.5H0V5.5H10.075L6.285 9.2865L7 10L12 5L7 0Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id="multi-item-example"
                  className="carousel slide carousel-multi-item"
                  data-bs-ride="carousel"
                  data-bs-interval="5000"
                >
                  <div className="pt-3 carousel-inner">
                    <div className="carousel-item active">
                      <div className="row align-items-center">
                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present1}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present2}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present3}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present4}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present5}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present6}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present7}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present8}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="row align-items-center">
                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present9}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present10}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present11}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present12}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present13}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present14}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present15}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present16}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="row align-items-center">
                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present17}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present18}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present19}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present20}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present21}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present22}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present23}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present24}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="row align-items-center">
                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present25}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present26}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>

                        <div className="col-md-3 land_cols_present">
                          <div className="land_present_img p-2">
                            <img
                              className="lazy"
                              src={Present27}
                              alt=""
                              title=""
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 d-lg-none d-sm-block">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="land_present_in_txt">Present in -</span>
                  </div>

                  <div>
                    <div
                      className="d-flex carousel-button-group"
                      style={{ gap: "15px" }}
                    >
                      <button
                        className="land_present_arrow_btn"
                        type="button"
                        data-bs-target="#multi-item-example1"
                        data-bs-slide="prev"
                        style={{ padding: "3px 11px" }}
                      >
                        <svg
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.39956 0.93335L6.051 1.56794L2.59789 5.03335H11.7773V5.94447H2.59789L6.051 9.39439L5.39956 10.0445L0.843995 5.48891L5.39956 0.93335Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                      <button
                        className="land_present_arrow_btn"
                        type="button"
                        data-bs-target="#multi-item-example1"
                        data-bs-slide="next"
                        style={{ padding: "3px 11px" }}
                      >
                        <svg
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.59654 0.933289L5.94509 1.56788L9.39821 5.03329H0.21875V5.94441H9.39821L5.94509 9.39433L6.59654 10.0444L11.1521 5.48885L6.59654 0.933289Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  id="multi-item-example1"
                  className="carousel slide carousel-multi-item"
                  data-bs-ride="carousel"
                  data-bs-interval="5000"
                >
                  <div className="pt-3 carousel-inner">
                    <div className="carousel-item active">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present1}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present2}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present3}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present4}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present5}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present6}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present7}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present8}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present9}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present10}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present11}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present12}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present13}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present14}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present15}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present16}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present17}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present18}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present19}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present20}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present21}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present22}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present23}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present24}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present25}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present26}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present27}
                            alt=""
                            title=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
